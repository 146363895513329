export default function MainscaleData() {

    return [
        {
            itemId: 1,
            itemCategory: { categoryAbbrv: "Em", itemCategoryId: 1 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "n",
            itemAge: [0, 4],
            itemText: "genießt passiv (z.B. entspannt sich beim Baden / Musikhören / In-der-Sonne-Sitzen)",
            itemComment: "empfangende Genussfähigkeit, auch als Teil der Sexualität im weitesten Sinne",
            itemValue: null
        },
        {
            itemId: 2,
            itemCategory: { categoryAbbrv: "Em", itemCategoryId: 2 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "f",
            itemAge: [0, 4],
            itemText: "<em>übernimmt die Stimmung</em> der Bezugsperson / Umgebung <em>unmittelbar</em> (z.B. Stress, Ausgeglichenheit), d.h. reagiert auf die Atmosphäre",
            itemComment: "Affektansteckung; geschieht automatisch, d.h. unbewusst. Zeichen fehlender Abgrenzungsfähigkeit.",
            itemSuccessor: 26,
            itemValue: null
        },
        {
            itemId: 3,
            itemCategory: { categoryAbbrv: "Em", itemCategoryId: 3 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "f",
            itemAge: [0, 4],
            itemText: "reagiert <em>bei kürzerem Alleinsein</em> (ca. 15 min.) mit Passivität, Weinen, suchthaftem Verhalten, Spannung oder Unruhe",
            itemComment: "Verlassenheitsreaktion; Angst setzt erst bei / nach der Trennung ein",
            itemSuccessor: 6,
            itemValue: null
        },
        {
            itemId: 4,
            itemCategory: { categoryAbbrv: "Em", itemCategoryId: 4 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "f",
            itemAge: [0, 4],
            itemText: "findet bei Missbehagen <em>nur</em> durch die <em>unmittelbare Zuwendung</em> (z.B. durch freundliche Ansprache, Körperkontakt, daneben sitzen) <em>und Situationsgestaltung</em> einer einfühlsam reagierenden Bezugsperson (die z.B. das Reizniveau steuert, Bedürfnisse befriedigt) zu emotionaler Ausgeglichenheit; erfolgt dies nicht, verfällt er in Apathie / Unruhe",
            itemComment: "Affektregulierung = Steuerung der Ausdrucksstärke von Gefühlen; gemeint ist sowohl die Besänftigung von Angst, Wut, Verzweiflung als auch die Überwindung von emotionaler Ausdruckslosig-keit und Interesselosigkeit",
            itemSuccessor: 6,
            itemValue: null
        },
        {
            itemId: 5,
            itemCategory: { categoryAbbrv: "Em", itemCategoryId: 5 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "f",
            itemAge: [4, 8],
            itemText: "beschäftigt sich interessiert und aufmerksam <em>nur</em>, wenn die Bezugsperson physisch und psychisch ansprechbar <em>im Raum</em> ist, d.h. diese kann einer eigenen Tätigkeit nachgehen",
            itemComment: "beginende Selbstbeschäftigung; unsichtbares emotionales Band zur Bezugsperson, Sichtkontakt",
            itemSuccessor: 10,
            itemValue: null
        },
        {
            itemId: 6,
            itemCategory: { categoryAbbrv: "Em", itemCategoryId: 6 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "f",
            itemAge: [8, 12],
            itemText: "benutzt, um schwierige Situationen (z.B. kurzfristige Trennung - ca. 15 Minuten) zu meistern, einen <em>Lieblingsgegenstand</em> (z.B. Schmusedecke, Stofftier, Kleidungsstück der Bezugsperson)",
            itemComment: "Übergangsobjekt ist Stellvertreter für die Bezugsperson und die eigene Person; dient dazu, Übergänge u. andere Situationen ohne die Bezugsperson zu erleichtern, hilft also zur Verselbständigung und Zuwendung zur Welt",
            itemSuccessor: 14,
            itemValue: null
        },
        {
            itemId: 7,
            itemCategory: { categoryAbbrv: "Em", itemCategoryId: 7 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "f",
            itemAge: [8, 12],
            itemText: "<em>hat oft nicht zu beruhigende Erregungszustände</em> bis hin zum Kontrollverlust (erkennbar an \"irrem\" Blick, fehlender Ansprechbarkeit) <em>bei geringfügigen oder nicht erkennbaren Anlässen</em> (z.B. geringfügige Einschränkungen, Störung eines gewohnten Ablaufs, Unterbrechen einer Tätigkeit, kurzes Warten)",
            itemComment: "Erlebenshintergrund: existentiell bedrohliche Verwirrung und Desorientierung, Verlust der Selbststeuerung",
            itemSuccessor: 30,
            itemValue: null
        },
        {
            itemId: 8,
            itemCategory: { categoryAbbrv: "Em", itemCategoryId: 8 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "n",
            itemAge: [12, 18],
            itemText: "freut sich an seinem Körper (Bewegung, Größe, Schnelligkeit, Stärke)",
            itemComment: "positives Körpererleben auch als Bestandteil sexueller Grenussfähigkeit im weitesten Sinne",
            itemValue: null
        },
        {
            itemId: 9,
            itemCategory: { categoryAbbrv: "Em", itemCategoryId: 9 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "f",
            itemAge: [12, 18],
            itemText: "ist stolz auf sein Tun <em>unabhängig von der Qualität des Ergebnisses</em> oder <em>dessen Wirkung</em> auf andere (z.B. strahlt, aufrechte Körperhaltung, will Bestätigung)",
            itemComment: "Das Tun als solches reicht für den Stolz; Selbstwirksamkeit als Wurzel für die spätere Leistungsmotivation",
            itemSuccessor: 12,
            itemValue: null
        },
        {
            itemId: 10,
            itemCategory: { categoryAbbrv: "Em", itemCategoryId: 10 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "i",
            itemAge: [12, 18],
            itemText: "beschäftigt sich 15 - 20 min. aufmerksam allein, wenn die Bezugsperson zwar nicht sichtbar, aber <em>in der Nähe und erreichbar</em> (z.B. im Nebenzimmer) ist",
            itemComment: "zunehmende Fähigkeit zur Selbstbeschäftigung. Zeichen zunehmender Autonomie",
            itemSuccessor: 18,
            itemValue: null
        },
        {
            itemId: 11,
            itemCategory: { categoryAbbrv: "Em", itemCategoryId: 11 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "f",
            itemAge: [18, 24],
            itemText: "verweigert sich / benutzt \"Nein\" nicht sachbezogen, nicht aus Überforderung mit der Situation, sondern <em>zur Selbstbehauptung</em> (z.B. verweigert etwas, was er mag; lehnt Hilfe ab)",
            itemComment: "Angst vor Autonomieverlust",
            itemSuccessor: 36,
            itemValue: null
        },
        {
            itemId: 12,
            itemCategory: { categoryAbbrv: "Em", itemCategoryId: 12 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "f",
            itemAge: [24, 30],
            itemText: "reagiert bei Misserfolg  mit heftiger Wut / Verzweiflung <em>ohne</em> Kontrollverlust (z.B. Schreien, Um-Sich-Schlagen)",
            itemComment: "geringe Frustrationstoleranz, weil er sich seiner begrenzten Fähigkeiten u. Abhängigkeit bewusst wird",
            itemSuccessor: 17,
            itemValue: null
        },
        {
            itemId: 13,
            itemCategory: { categoryAbbrv: "Em", itemCategoryId: 13 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "n",
            itemAge: [24, 30],
            itemText: "äußert klare Geschmacksvorstellungen oder Vorlieben (z.B. Lieblingsgericht, bestimmte Kleidung, Tätigkeiten)",
            itemComment: "Zeichen von Autonomie",
            itemValue: null
        },
        {
            itemId: 14,
            itemCategory: { categoryAbbrv: "Em", itemCategoryId: 14 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "n",
            itemAge: [30, 36],
            itemText: "stabilisiert sich in emotional schwierigen Alltagssituationen <em>innerhalb kurzer Zeit</em> (ca. 5 Minuten) durch <em>kurze freundliche,</em> situationsbezogene <em>Ansprache</em> der Bezugsperson (\"kann jedem mal passieren..., das nächste Mal schaffst du es..., xy kommt wieder\") <em>oder</em> durch vergleichbare <em>verbale Selbstberuhigung</em>)",
            itemComment: "hat die konstruktiven Bewältigungsformen der \"guten\" Bezugsperson verinnerlicht; Bestandteil der sicheren Bindung",
            itemValue: null
        },
        {
            itemId: 15,
            itemCategory: { categoryAbbrv: "Em", itemCategoryId: 15 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "n",
            itemAge: [30, 36],
            itemText: "versucht nach Fehlverhalten negative Reaktionen der Bezugsperson zu verhindern, <em>bevor</em> diese reagiert (z.B. passt sich an, will wieder \"lieb\" sein, entschuldigt sich), weil er sich seiner Normübertretung bewusst ist",
            itemComment: "Angst vor Strafe und Liebesverlust als Weiterentwicklung der Trennungsangst; Beginn des Schuldbewusstseins. Normen sind ansatzweise verinnerlicht",
            itemValue: null
        },
        {
            itemId: 16,
            itemCategory: { categoryAbbrv: "Em", itemCategoryId: 16 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "i",
            itemAge: [36, 48],
            itemText: "hält viele Gebote <em>für begrenzte Zeit</em> (ca. 30 Minuten) ohne Aufsicht ein",
            itemComment: "Zeichen von wachsender Selbstkontrolle und Normübernahme",
            itemSuccessor: 39,
            itemValue: null
        },
        {
            itemId: 17,
            itemCategory: { categoryAbbrv: "Em", itemCategoryId: 17 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "n",
            itemAge: [36, 48],
            itemText: "bemüht sich <em>Aufgaben</em> / Vorhaben <em>zunächst allein</em> zu bewältigen, sucht jedoch <em>vertrauensvoll Hilfe</em>, wenn es ihm nicht gelingt",
            itemComment: "Bestandteil der sicheren Bindung, emotionale Konstanz, d.h. verbindet Autonomie mit sozialer Bezogenheit",
            itemValue: null
        },
        {
            itemId: 18,
            itemCategory: { categoryAbbrv: "Em", itemCategoryId: 18 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "n",
            itemAge: [48, 60],
            itemText: "nutzt ein <em>breites</em> Repertoire (<em>mindestens fünf</em> Möglichkeiten) und beschäftigt sich damit <em>allein mindestens 30-45 Minuten</em> aktiv, solange er jederzeit eine Bezugsperson aufsuchen kann",
            itemComment: "Zeichen von Autonomie und Interessensvielfalt",
            itemValue: null
        },
        {
            itemId: 19,
            itemCategory: { categoryAbbrv: "Em", itemCategoryId: 19 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "n",
            itemAge: [60, 72],
            itemText: "schätzt seine Fähigkeiten <em>häufig realistisch</em> ein (z.B. weiß, dass er neue Aufgaben erst üben muss; setzt sich relativ realistische Ziele)",
            itemComment: "beginnende Fähigkeit zur Selbstreflexion; Erfolgsorientierung als Aspekt der Leistungsmotivation",
            itemValue: null
        },
        {
            itemId: 20,
            itemCategory: { categoryAbbrv: "Em", itemCategoryId: 20 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "n",
            itemAge: [60, 72],
            itemText: "reguliert <em>Affekte mittlerer Stärke</em> (z. B. Ärger, Freude, Traurigkeit) auf <em>unterschiedliche</em> Weise <em>konstruktiv</em>; d.h. nutzt ein breites Verhaltensrepertoire von mindestens drei Möglichkeiten (z.B. Lieblingsgegenstand, eine Lieblingsbeschäftigung, Trost suchen bei einer Bezugsperson, gedankliche Selbstberuhigung)",
            itemComment: "besitzt Affekt- und Impulskontrolle als Teil der Selbstkontrolle",
            itemValue: null
        },
        {
            itemId: 21,
            itemCategory: { categoryAbbrv: "Em", itemCategoryId: 21 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "n",
            itemAge: [72, 96],
            itemText: "reflektiert mit der Bezugsperson <em>von sich aus</em> alle ihn beschäftigenden \"Lebensfragen\" (z.B. \"Warum schlägt X Schwächere und hört nicht auf, wenn sie weinen?\", \"Warum kann ich das nicht, obwohl ich mich anstrenge?\") und fragt gezielt weiter, bis ihm die Erklärung genügt",
            itemComment: "Bezugsperson als \"Weltdeuter\" und \"Lebensberater\"",
            itemValue: null
        },
        {
            itemId: 22,
            itemCategory: { categoryAbbrv: "Em", itemCategoryId: 22 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "n",
            itemAge: [96, 144],
            itemText: "beschäftigt sich <em>allein ausdauernd und konzentriert (mind. 1 Std.)</em> mit einer <em>vorgegebenen</em> Aufgabe (z.B. Hausaufgaben, Haushaltstätigkeiten, WfbM-Arbeiten)",
            itemComment: "Anstrengungsbereitschaft als Teil der Leistungsmotivation",
            itemValue: null
        },
        {
            itemId: 23,
            itemCategory: { categoryAbbrv: "Em", itemCategoryId: 23 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "n",
            itemAge: [144, 216],
            itemText: "setzt sich allein oder mit Gleichaltrigen <em>differenziert</em> mit verschiedenen Themen seiner Lebenssituation auseinander (Familie, Herkunft, sozialer Status, Behinderung, Zukunftspläne)",
            itemComment: "differenzierte Selbstreflexion",
            itemValue: null
        },
        {
            itemId: 24,
            itemCategory: { categoryAbbrv: "Em", itemCategoryId: 24 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "n",
            itemAge: [216, 1188],
            itemText: "trifft <em>weitreichende</em> Entscheidungen <em>selbstverantwortlich</em> und geht auch mit unangenehmen Konsequenzen konstruktiv um, d.h. kann sich gut einschätzen, kontrollieren und durchsetzen",
            itemComment: "Merkmal der erwachsenen selbstverantwortlichen Persönlichkeit",
            itemValue: null
        },
        {
            itemId: 25,
            itemCategory: { categoryAbbrv: "So", itemCategoryId: 1 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "i",
            itemAge: [0, 4],
            itemText: "reagiert positiv, wenn seine Signale (z.B. Mimik, Gestik, Laute, ggf. Wörter) aufgegriffen werden, antwortet (evtl. mit Variationen), so dass ein Spiegeldialog entsteht",
            itemComment: "Primärer Dialog; Zeichen von entstehender Bindung, beginnender Autonomie und Interesse am Dialog; erlebt dabei seine Selbstwirksamkeit",
            itemSuccessor: 26,
            itemValue: null
        },
        {
            itemId: 26,
            itemCategory: { categoryAbbrv: "So", itemCategoryId: 2 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "n",
            itemAge: [8, 12],
            itemText: "achtet auf den emotionalen Ausdruck (Mimik, Stimmklang) der Bezugsperson und <em>reagiert passend</em> (z.B. ist sensibel für Lob und Ärger oder Freude und Schreck der Bezugsperson)",
            itemComment: "Affektabstimmung: bewusste Aufnahme des Gefühls vom Gegenüber; dies führt zum Lernen von Gefühlsreaktionen, Voraussetzung für Empathie und Dialogfähigkeit",
            itemValue: null
        },
        {
            itemId: 27,
            itemCategory: { categoryAbbrv: "So", itemCategoryId: 3 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "f",
            itemAge: [8, 12],
            itemText: "sucht bei Selbstbeschäftigung in <em>kurzen</em> Abständen (bis zu ca. 15 Minuten) Blick- / Körperkontakt zur Bezugsperson, um bei ihr emotional aufzutanken oder um Trost bzw. Schutz zu holen",
            itemComment: "Bezugsperson ist Heimatstützpunkt (\"unsichtbares Band\"); Zeichen für entstehende Bindung",
            itemSuccessor: 10,
            itemValue: null
        },
        {
            itemId: 28,
            itemCategory: { categoryAbbrv: "So", itemCategoryId: 4 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "f",
            itemAge: [12, 18],
            itemText: "befolgt \"Nein\" <em>nur</em> in Gegenwart der Bezugsperson, solange sie auf die Einhaltung achtet",
            itemComment: "\"Nein\" hat losgelöst von der Bezugsperson keine Bedeutung, oder die Selbstkontrolle fehlt.",
            itemSuccessor: 16,
            itemValue: null
        },
        {
            itemId: 29,
            itemCategory: { categoryAbbrv: "So", itemCategoryId: 5 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "f",
            itemAge: [18, 24],
            itemText: "versucht Trennung und / oder Alleinsein <em>aktiv</em> zu verhindern, (z.B. beschattet die Bezugsperson; zieht die Aufmerksamkeit auf sich)",
            itemComment: "Verlassenheitsangst; setzt voraus, dass er sich die Folgen der Trennung, nämlich Alleinsein, vorstellen kann",
            itemSuccessor: 14,
            itemValue: null
        },
        {
            itemId: 30,
            itemCategory: { categoryAbbrv: "So", itemCategoryId: 6 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "f",
            itemAge: [18, 24],
            itemText: "wird bei Widerstand <em>ohne</em> Kontrollverlust wütend / schreit, wenn die Bezugsperson Grenzen setzt oder einen eigenen Willen bekundet",
            itemComment: "Wut mit geringfügiger Selbststeuerung; kämpft um seine Autonomie, ohne die Symbiose verlieren zu wollen. Angst vor Symbiose- und Autonomieverlust",
            itemSuccessor: 36,
            itemValue: null
        },
        {
            itemId: 31,
            itemCategory: { categoryAbbrv: "So", itemCategoryId: 7 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "n",
            itemAge: [18, 24],
            itemText: "bittet um Hilfe <em>und wartet</em> auf die Erledigung der Bitte",
            itemComment: "Zeichen von beginnender Impulskontrolle",
            itemValue: null
        },
        {
            itemId: 32,
            itemCategory: { categoryAbbrv: "So", itemCategoryId: 8 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "f",
            itemAge: [24, 30],
            itemText: "<em>testet</em> immer wieder in vielen - auch bekannten Situationen - die Gültigkeit von Ge- und Verboten (z.B. bei unterschiedlichen Personen, zu verschiedenen Zeiten)",
            itemComment: "dient dazu, die Allgemeingültigkeit von Ver- / Geboten zu erfassen und die Zuverlässigkeit der Bezugsperson zu überprüfen",
            itemSuccessor: 16,
            itemValue: null
        },
        {
            itemId: 33,
            itemCategory: { categoryAbbrv: "So", itemCategoryId: 9 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "f",
            itemAge: [24, 30],
            itemText: "zeigt Empathie <em>nur</em> dann, wenn eigene Interessen nicht betroffen sind (z.B. fragt nach; tröstet, wenn jemand traurig ist)",
            itemComment: "Affektabgleich und nachahmende empathische Reaktion, ohne eigene Bedürfnisse zurückstellen zu können",
            itemSuccessor: 40,
            itemValue: null
        },
        {
            itemId: 34,
            itemCategory: { categoryAbbrv: "So", itemCategoryId: 10 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "n",
            itemAge: [30, 36],
            itemText: "nimmt das Angebot, bei einfachen Haushaltstätigkeiten zu helfen, gerne an (z.B. Kochen, Tisch decken)",
            itemComment: "beginnende Leistungsmotivation; Erleben von Symbiose und Kompetenz im gemeinsamen Tun",
            itemValue: null
        },
        {
            itemId: 35,
            itemCategory: { categoryAbbrv: "So", itemCategoryId: 11 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "n",
            itemAge: [30, 36],
            itemText: "teilt begehrte Dinge <em>bereitwillig</em> mit Anderen (z. B. Süßigkeiten)",
            itemComment: "Zeichen erfolgreicher Normübernahme",
            itemValue: null
        },
        {
            itemId: 36,
            itemCategory: { categoryAbbrv: "So", itemCategoryId: 12 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "n",
            itemAge: [30, 36],
            itemText: "akzeptiert bei <em>Widersprüchen</em> zwischen seinen Absichten und den Wünschen der Bezugsperson <em>\"Mitttelwege\"</em> (z.B. Kompromisse, Alternativen, Aufschub auf späteren Zeitpunkt",
            itemComment: "Zeichen der emotionalen Konstanz oder Ausdruck der Überanpassung",
            itemValue: null
        },
        {
            itemId: 37,
            itemCategory: { categoryAbbrv: "So", itemCategoryId: 13 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "n",
            itemAge: [36, 48],
            itemText: "gestaltet im alltäglichen Umfeld aus <em>eigenem Antrieb</em> einfache gelingende gemeinsame Aktivitäten mit \"gleichrangigen\" vertrauten Personen <em>ohne</em> die primäre Bezugsperson (z.B. innerhalb der Tagessbetreuung, Freunde)",
            itemComment: "Zeichen von sozialer Kompetenz",
            itemValue: null
        },
        {
            itemId: 38,
            itemCategory: { categoryAbbrv: "So", itemCategoryId: 14 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "f",
            itemAge: [48, 60],
            itemText: "kämpft mit sozial <em>unerwünschten</em> Verhaltensweisen um seinen <em>Platz in der Gruppe</em> (z.B. petzt, rivalisiert, drangsaliert, ist geltungsbedürftig)",
            itemComment: "will die Aufmerksamkeit der Gruppe und der Bezugsperson; Angst in der Gruppe unterzugehen",
            itemSuccessor: 46,
            itemValue: null
        },
        {
            itemId: 39,
            itemCategory: { categoryAbbrv: "So", itemCategoryId: 15 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "n",
            itemAge: [48, 60],
            itemText: "passt sich <em>bewusst</em> Gruppennormen und Verhaltenserwartungen an, die <em>seinem momentanen Bedürfnis widersprechen</em>, d.h. kennt die Regeln und hält sie ein (z.B. respektiert das Eigentum Anderer, obwohl er es gerne hätte)",
            itemComment: "Teil der Gruppenfähigkeit, Normbewusstsein und Selbstkontrolle",
            itemValue: null
        },
        {
            itemId: 40,
            itemCategory: { categoryAbbrv: "So", itemCategoryId: 16 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "f",
            itemAge: [48, 60],
            itemText: "versteht <em>nur nach Erklärung</em>, dass andere Menschen etwas anderes sehen, wollen, fühlen als er selbst, und verhält sich dann entsprechend (z.B. stellt sein eigenes Bedürfnis zurück)",
            itemComment: "egozentrisches Denken tritt zurück, Einfühlungsvermögen wächst",
            itemSuccessor: 44,
            itemValue: null
        },
        {
            itemId: 41,
            itemCategory: { categoryAbbrv: "So", itemCategoryId: 17 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "n",
            itemAge: [48, 60],
            itemText: "<em>löst kleinere soziale Konflikte</em> mit <em>\"Gleichrangigen\" selbständig</em> und und für alle Beteiligten <em>befriedigend</em> (z. B. Kompromiss, akzeptiert Alternativen)",
            itemComment: "Bestandteil der sicheren Bindung, emotionale Konstanz",
            itemValue: null
        },
        {
            itemId: 42,
            itemCategory: { categoryAbbrv: "So", itemCategoryId: 18 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "n",
            itemAge: [60, 72],
            itemText: "zeigt werbende Verhaltensweisen (z.B. Flirten, Schmeicheln, Fürsorglichkeit, männliche Kraft, sich schön machen)",
            itemComment: "dient dem Aufbau oder der Darstellung der Geschlechtsrolle",
            itemValue: null
        },
        {
            itemId: 43,
            itemCategory: { categoryAbbrv: "So", itemCategoryId: 19 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "n",
            itemAge: [60, 72],
            itemText: "nimmt <em>auf eigenen Wunsch</em> gern allein an <em>regelmäßig</em> stattfindenden Freizeitgruppen teil (z.B. kreative Angebote, Sport, Tanz)",
            itemComment: "stabile Eigenmotivataion; Bestandteil der Gruppenfähigkeit, Aspekt der autonomen Teilhabe",
            itemValue: null
        },
        {
            itemId: 44,
            itemCategory: { categoryAbbrv: "So", itemCategoryId: 20 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "n",
            itemAge: [72, 96],
            itemText: "ist fähig, sich <em>von sich aus</em> in andere Menschen <em>hineinzuversetzen und</em> sein <em>Handeln darauf abzustimmen</em>",
            itemComment: "Perspektivwechsel; Einfühlungsvermögen; verbindet die eigene Sichtweise mit der des Gegenübers",
            itemValue: null
        },
        {
            itemId: 45,
            itemCategory: { categoryAbbrv: "So", itemCategoryId: 21 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "n",
            itemAge: [96, 144],
            itemText: "plant und verbringt seine Freizeit <em>selbständig</em> (allein oder mit Freunden) in der Öffentlichkeit (Café, Kino, Disco, Natur)",
            itemComment: "Aspekte der autonomen Teilhabe",
            itemValue: null
        },
        {
            itemId: 46,
            itemCategory: { categoryAbbrv: "So", itemCategoryId: 22 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "n",
            itemAge: [96, 144],
            itemText: "verhält sich <em>sozial kompetent</em> in der Gruppe (kann sich einfügen, durchsetzen und fair kämpfen)",
            itemComment: "Gruppenfähigkeit ist ausdifferenziert",
            itemValue: null
        },
        {
            itemId: 47,
            itemCategory: { categoryAbbrv: "So", itemCategoryId: 23 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "f",
            itemAge: [144, 216],
            itemText: "orientiert sich vorzugsweise an der <em>Gruppe der Gleichrangigen</em> und ihrer Idole, um sich <em>von Autoritäten abzugrenzen</em>",
            itemComment: "dient der Findung der eigenen Identität und der Verbundenheit mit Gleichrangigen",
            itemSuccessor: 50,
            itemValue: null
        },
        {
            itemId: 48,
            itemCategory: { categoryAbbrv: "So", itemCategoryId: 24 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "n",
            itemAge: [144, 216],
            itemText: " pflegt sexuell getönte Freundschaften",
            itemComment: "sexuelles Interesse spielt eine deutliche Rolle, nicht nur der Wunsch nach Geborgenheit",
            itemValue: null
        },
        {
            itemId: 49,
            itemCategory: { categoryAbbrv: "So", itemCategoryId: 25 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "f",
            itemAge: [144, 216],
            itemText: "strebt im Kontakt mit Bezugspersonen demonstrativ nach Selbstbestimmung",
            itemComment: "dient der Ablösung und Identitätsfindung",
            itemSuccessor: 24,
            itemValue: null
        },
        {
            itemId: 50,
            itemCategory: { categoryAbbrv: "So", itemCategoryId: 26 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "n",
            itemAge: [216, 1188],
            itemText: "übernimmt Verantwortung für selbst gewählte Beziehungen (Freunde, Partner, Familie) und berücksichtigt bei der Gestaltung angemessen die Bedürfnisse des Gegenübers",
            itemComment: "Merkmal ausgereifter und verantwortungsvoller Beziehungsfähigkeit",
            itemValue: null
        },
        {
            itemId: 51,
            itemCategory: { categoryAbbrv: "DE", itemCategoryId: 1 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "i",
            itemAge: [0, 4],
            itemText: "führt willkürlich einfache <em>eingliedrige</em> Handlungen durch, die ein <em>sinnliches \"Erlebnis\"</em> vermitteln (z.B. nuckelt am Daumen, schlägt mit der Hand / dem Fuß gegen einen Gegenstand)",
            itemComment: "primäre Kreisreaktion, Sinn der Handlung liegt in der Selbstwahr-nehmung / im Tun",
            itemSuccessor: 52,
            itemValue: null
        },
        {
            itemId: 52,
            itemCategory: { categoryAbbrv: "DE", itemCategoryId: 2 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "i",
            itemAge: [4, 8],
            itemText: "führt einfache <em>mehrgliedrige</em> Handlungen aus, die einen <em>Effekt</em> bewirken (z.B. nimmt einen Löffel, schlägt auf den Tisch, freut sich über das Geräusch)",
            itemComment: "sekundäre Kreisreaktion, erfasst den Wenn-dann-Zusammenhang; Ziele der Handlung sind nur der interessante Effekt und das Erleben von Selbstwirk-samkeit.",
            itemSuccessor: 55,
            itemValue: null
        },
        {
            itemId: 53,
            itemCategory: { categoryAbbrv: "DE", itemCategoryId: 3 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "n",
            itemAge: [8, 12],
            itemText: "<em>wechselt</em> bei gemeinsamen Tätigkeiten mit seiner <em>Aufmerksamkeit</em> zwischen dem Gegenstand und dem Gegenüber (z.B. schlägt auf einen Gegenstand und schaut dann zur Bezugsperson), erkennbar am Blick und an Laut-/ Wortäußerungen",
            itemComment: "Referenzieller Blickkontakt, d.h. kann seine Aufmerksamkeit auf zwei Aspekte einer Situation richten; Voraussetzung für Spracherwerb und gemeinsame Tätigkeiten",
            itemValue: null
        },
        {
            itemId: 54,
            itemCategory: { categoryAbbrv: "DE", itemCategoryId: 4 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "i",
            itemAge: [8, 12],
            itemText: "sucht und findet einen Gegenstand, wenn er das <em>Verschwinden beobachtet</em> und den <em>Aufbewahrungsort registriert</em> hat",
            itemComment: "Beginn der Objektpermanenz: weiß um die Existenz eines nicht unmittelbar vor Augen befindlichen Gegenstandes; beginnende Vorstellungskraft",
            itemSuccessor: 59,
            itemValue: null
        },
        {
            itemId: 55,
            itemCategory: { categoryAbbrv: "DE", itemCategoryId: 5 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "i",
            itemAge: [8, 12],
            itemText: "löst <em>einfache</em> Aufgaben, indem er <em>bekannte</em> Handlungsschritte <em>zweckmäßig</em> hintereinanderschaltet (z.B. schiebt einen Gegenstand weg, um an einen anderen dahinter sichtbaren zu gelangen)",
            itemComment: "Mittel-Zweck Relation, beginnt seine Handlungsschritte in eine zweckmäßige Reihenfolge zu bringen; beginnende Vorstellungskraft",
            itemSuccessor: 57,
            itemValue: null
        },
        {
            itemId: 56,
            itemCategory: { categoryAbbrv: "DE", itemCategoryId: 6 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "i",
            itemAge: [12, 18],
            itemText: "experimentiert <em>spielerisch und konzentriert</em> mit verschiedenen Materialien (z.B. Wasser, Sand, Suppe), d.h. variiert eine Handlung und beobachtet interessiert die Veränderung des Ergebnisses",
            itemComment: "tertiäre Kreisreaktion, experimentelles Funktionsspiel; Lust am Spiel, erlebnisbezogen, nicht zweck- oder ergebnisorientiert.",
            itemSuccessor: 58,
            itemValue: null
        },
        {
            itemId: 57,
            itemCategory: { categoryAbbrv: "DE", itemCategoryId: 7 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "n",
            itemAge: [12, 18],
            itemText: "gebraucht im <em>Blickfeld befindliche Gegenstände als Hilfsmittel</em> (z.B. Löffel zum Essen; klettert auf einen Stuhl, um an einen Gegenstand zu gelangen) und setzt sie zweckmäßig als \"Werkzeug\" ein",
            itemComment: "Entdeckung der Werkzeugfunktion ",
            itemValue: null
        },
        {
            itemId: 58,
            itemCategory: { categoryAbbrv: "DE", itemCategoryId: 8 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "n",
            itemAge: [18, 24],
            itemText: "probiert <em>gezielt</em> verschiedene Möglichkeiten, um ein einfaches Problem zu lösen (z.B. beim Puzzlen)",
            itemComment: "probiert gezielt mehrere Handlungsmuster aus, um ein Problem zu lösen, d.h. gezieltes Experimentieren; Lernen am Erfolg",
            itemValue: null
        },
        {
            itemId: 59,
            itemCategory: { categoryAbbrv: "DE", itemCategoryId: 9 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "n",
            itemAge: [18, 24],
            itemText: "sucht einen Gegenstand, dessen <em>Verschwinden</em> er <em>nicht beobachtet</em> hat; d.h. sucht Dinge, die nicht am vertrauten Ort sind",
            itemComment: "Objektpermanenz weitgehend vorhanden",
            itemValue: null
        },
        {
            itemId: 60,
            itemCategory: { categoryAbbrv: "DE", itemCategoryId: 10 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "n",
            itemAge: [18, 24],
            itemText: "übernimmt gehörte Wörter oder beobachtete Handlungen in das eigene Verhaltensrepertoire und zeigt sie zu einem <em>späteren Zeitpunkt</em> und in einer anderen Situation (z.B. im Alltag, Spiel)",
            itemComment: "ist fähig zur aufgeschobenen Nachahmung; Teil der Symbolfunktion, Lernen am Modell",
            itemValue: null
        },
        {
            itemId: 61,
            itemCategory: { categoryAbbrv: "DE", itemCategoryId: 11 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "f",
            itemAge: [24, 30],
            itemText: "handelt so, als ob er glaubt, dass sein Gegenüber die Welt <em>genauso wahrnimmt wie er</em>, ebenso fühlt / denkt (z.B. geht davon aus, dass die eigene Lieblingsmusik allen gefällt; dass der Telefonpartner seine Gestik sieht)",
            itemComment: "denkt egozentrisch, d.h. geht beim Denken von sich aus, kann sich gedanklich nicht in einen anderen Menschen hineinversetzen",
            itemSuccessor: 40,
            itemValue: null
        },
        {
            itemId: 62,
            itemCategory: { categoryAbbrv: "DE", itemCategoryId: 12 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "f",
            itemAge: [24, 30],
            itemText: "vermischt <em>ohne jeden Zweifel innere</em> (Phantasien, Wünsche, Ängste, Träume usw.) <em>und äußere Wirklichkeit</em> (z.B. sagt, dass er zuhause einen Hund hat, was seinem Wunsch, aber nicht der Realität entspricht)",
            itemComment: "Vorstellung und äußere Realität bilden noch eine Einheit",
            itemSuccessor: 68,
            itemValue: null
        },
        {
            itemId: 63,
            itemCategory: { categoryAbbrv: "DE", itemCategoryId: 13 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "f",
            itemAge: [24, 30],
            itemText: "behandelt oder bezeichnet <em>alle Dinge als lebendig / menschlich</em> (z.B. die genau vor mir zugefallene Tür will mich ärgern), d.h. sie können sich freuen, traurig sein, Schmerzen fühlen oder jemanden ärgern",
            itemComment: "denkt animistisch / anthropomorph, d.h. macht keinen Unterschied zwischen belebt und unbelebt",
            itemSuccessor: 69,
            itemValue: null
        },
        {
            itemId: 64,
            itemCategory: { categoryAbbrv: "DE", itemCategoryId: 14 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "f",
            itemAge: [36, 48],
            itemText: "erklärt sich Zusammenhänge auf <em>unrealistische</em> Weise,d.h. durch <em>beliebige Ähnlichkeiten</em> oder <em>besondere Kräfte</em> (z. B. wenn es draußen dunkel wird, macht jemand den Lichtschalter am Himmel aus. Wer mit dem weißen Würfel spielt gewinnt das Spiel)",
            itemComment: "denkt analog. Analogie wird nicht als Vergleich erkannt, sondern als Tatsachenbeschreibung aufgefasst",
            itemSuccessor: 76,
            itemValue: null
        },
        {
            itemId: 65,
            itemCategory: { categoryAbbrv: "DE", itemCategoryId: 15 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "n",
            itemAge: [36, 48],
            itemText: "ordnet <em>passende</em> Gegenstände zu (z.B. jedem Teller eine Gabel, ein Messer), d. h. erkennt zusammengehörige Elemente",
            itemComment: "Weiterentwicklung der Kategorisierung; Eins-zu-Eins-Zuordnung",
            itemValue: null
        },
        {
            itemId: 66,
            itemCategory: { categoryAbbrv: "DE", itemCategoryId: 16 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "i",
            itemAge: [36, 48],
            itemText: "plant mehrgliedrige bekannte Handlungen selbständig <em>schrittweise bei der Durchführung</em> (z.B. deckt den Tisch, holt sich den Teller, merkt was fehlt, holt den Löffel, merkt… etc.)",
            itemComment: "Handlungsplanung; überblickt noch nicht die vollständige Handlung",
            itemSuccessor: 67,
            itemValue: null
        },
        {
            itemId: 67,
            itemCategory: { categoryAbbrv: "DE", itemCategoryId: 17 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "i",
            itemAge: [48, 60],
            itemText: "plant eine <em>mehrgliedrige bekannte</em> Handlung in <em>vertrauter</em> Umgebung <em>vollständig, bevor</em> er sie selbständig durchführt (z.B. deckt den Tisch mit täglich benutztem Geschirr vollständig, d.h. er kann vorher sagen, was er braucht) bzw. legt sich vorher alle benötigten Dinge zurecht",
            itemComment: "Handlungsplanung; überblickt die vollständige Handlungskette",
            itemSuccessor: 74,
            itemValue: null
        },
        {
            itemId: 68,
            itemCategory: { categoryAbbrv: "DE", itemCategoryId: 18 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "f",
            itemAge: [48, 60],
            itemText: "spricht von großen Wünschen, Ängsten und Ärger so, als seien sie Realität, d.h. <em>verwechselt Vorstellung und Realität</em> noch <em>bei emotional sehr bedeutsamen Themen</em>",
            itemComment: "Trennung von Vorstellung und äußerer Realität ist überwiegend vorhanden",
            itemSuccessor: 73,
            itemValue: null
        },
        {
            itemId: 69,
            itemCategory: { categoryAbbrv: "DE", itemCategoryId: 19 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "i",
            itemAge: [48, 60],
            itemText: "unterscheidet <em>klar zwischen belebt und unbelebt auf anschaulichem Niveau</em>, indem er ein Merkmal als Begründung nennt. Dieses kann zutreffen muss aber nicht. Fehleinschätzungen dürfen vorkommen bei Lieblingesdingen und Naturphänomenen (z.B. die Wolke ist lebendig, weil sie sich bewegt)",
            itemComment: "das animistische / anthropomorphe Denken tritt weitgehend zurück",
            itemSuccessor: 76,
            itemValue: null
        },
        {
            itemId: 70,
            itemCategory: { categoryAbbrv: "DE", itemCategoryId: 20 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "f",
            itemAge: [48, 60],
            itemText: "<em>begründet</em> seine Einschätzung anhand eines <em>einzigen</em>, deutlich <em>sichtbaren</em> Merkmals (vernachlässigt alle anderen) (z.B. ein in vier Stücke geschnittener Apfel ist mehr als ein unzerteilter)",
            itemComment: "denkt zentriert und anschauungsgebunden, d.h. die Anschauung dient als Beurteilungsmaßstab, weil eine übergeordnete Bezugsgöße fehlt.",
            itemSuccessor: 75,
            itemValue: null
        },
        {
            itemId: 71,
            itemCategory: { categoryAbbrv: "DE", itemCategoryId: 21 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "n",
            itemAge: [60, 72],
            itemText: "fragt <em>gezielt</em> nach <em>sachgerechten</em> Erklärungen für <em>nicht offensichtliche Zusammenhänge</em> (z.B. Warum ist der Vogel lebendig und die Feder nicht?; Wo war ich, bevor ich auf die Welt kam? Warum gibt es Krieg, wenn die Menschen doch Frieden wollen?) und merkt sie sich",
            itemComment: "will die Wirklichkeit rational erfassen; magisches und animistische Denken reduzieren sich",
            itemValue: null
        },
        {
            itemId: 72,
            itemCategory: { categoryAbbrv: "DE", itemCategoryId: 22 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "n",
            itemAge: [60, 72],
            itemText: "<em>erklärt</em> bei <em>einfachen</em> vertrauten Handlungen seine <em>Strategie</em> und <em>merkt</em>, wenn sie nicht aufgeht (z.B. sagt, woran er sich orientiert, um zu beurteilen, ob ein Puzzleteil in eine Lücke passt; wie er sich verhalten muss, um bei seinem Gegenüber die gewünschte Wirkung zu erzielen)",
            itemComment: "Metakognition = Fähigkeit, eigene Denkmethoden und Problemlösestrategien zu beschreiben (\"Denken über das Denken\"); Teil der Selbstreflexion und der Fähigkeit, eigene Gedankenfehler zu erkennen und zu korrigieren",
            itemValue: null
        },
        {
            itemId: 73,
            itemCategory: { categoryAbbrv: "DE", itemCategoryId: 23 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "n",
            itemAge: [72, 96],
            itemText: "<em>benutzt rationale Prüfstrategien</em>, um zu entscheiden, ob eine Vorstellung (Wunsch, Angst, Handlungsergebnis) nur in seinem Kopf wirklich ist oder von Anderen geteilt wird, <em>und sucht nach Gründen für die Abweichung</em> (z.B. überprüft durch Nachfragen, ob seine Angst, die Freundin trennt sich von ihm, Wirklichkeit ist); überprüft, ob sein Rechenergebnis stimmt, mit geeigneten Methoden",
            itemComment: "sichere Trennung zwischen Phantasie und Realität. Gute Realitätsprüfung und differenzierte Metakognition",
            itemValue: null
        },
        {
            itemId: 74,
            itemCategory: { categoryAbbrv: "DE", itemCategoryId: 24 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "n",
            itemAge: [72, 96],
            itemText: "erarbeitet sich <em>neue</em> Handlungsabläufe selbstständig nach visueller <em>Vorlage</em> (z.B. Bauplan)",
            itemComment: "versteht symbolisierte Informationen und setzt sie in Handlung um",
            itemValue: null
        },
        {
            itemId: 75,
            itemCategory: { categoryAbbrv: "DE", itemCategoryId: 25 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "n",
            itemAge: [72, 96],
            itemText: "berücksichtigt bei der Beurteilung eines Sachverhaltes das Zusammenwirken von zwei bis drei Merkmalen und begründet das Ergebnis korrekt (z.B. ein in vier Stücke geschnittener Apfel ist genau so viel wie der ganze, wenn man ihn wieder zusammensetzt und kein Stück weggenommen hat; es ist Sommer, weil die Laubbäume grün und die Tage länger sind als die Nacht)",
            itemComment: "Dezentriertes Denken. Mengenkonstanz = Mengen bleiben trotz Veränderung der äußeren Form gleich; Teil des Invarianzprinzips",
            itemValue: null
        },
        {
            itemId: 76,
            itemCategory: { categoryAbbrv: "DE", itemCategoryId: 26 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "n",
            itemAge: [72, 96],
            itemText: "teilt die kulturell übliche Vorstellung von Realität, d.h. versteht animistische, analoge oder magische Vorstellungen korrekt als bildhafte Vergleiche oder Redensarten (z.B. weiß: lebendig ist nur ein Wesen, das atmet)",
            itemComment: "denkt rational, d.h. hat die Denkformen des symbolisch-anschaulichen Denkens überwunden",
            itemValue: null
        },
        {
            itemId: 77,
            itemCategory: { categoryAbbrv: "DE", itemCategoryId: 27 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "n",
            itemAge: [96, 144],
            itemText: "beherrscht die <em>vier Grundrechenarten</em> und wendet sie <em>selbständig</em> an (z.B. beim Umgang mit Geld)",
            itemComment: "beherrscht den Zahlbegriff und das Prinzip der Reihenbildung = Erfassen des einer Reihe zugrunde liegenden Gesetzes, lernt dieses zur Vereinfachung seiner Handlungen zu benutzen",
            itemValue: null
        },
        {
            itemId: 78,
            itemCategory: { categoryAbbrv: "DE", itemCategoryId: 28 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "n",
            itemAge: [144, 216],
            itemText: "versteht <em>komplexe theoretische</em> Zusammenhänge und gibt sie korrekt wieder (z.B. schwierige philosophische Texte, mathematische oder naturwissenschaftliche Gesetze)",
            itemComment: "Aspekt des formal-logischen Denkens",
            itemValue: null
        },
        {
            itemId: 79,
            itemCategory: { categoryAbbrv: "SpP", itemCategoryId: 1 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "i",
            itemAge: [8, 12],
            itemText: "äußert Wünsche und Abneigungen durch klare <em>präverbale</em> Laute (z.B. \"äh\") nicht nur durch Schreien",
            itemComment: "eindeutige Ruflaute",
            itemSuccessor: 80,
            itemValue: null
        },
        {
            itemId: 80,
            itemCategory: { categoryAbbrv: "SpP", itemCategoryId: 2 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "i",
            itemAge: [12, 18],
            itemText: "drückt Absichten aus durch <em>Zeigen</em>, An-der-Hand-Ziehen, <em>einzelne</em> Wörter",
            itemComment: "Ein-Wort-Sätze",
            itemSuccessor: 81,
            itemValue: null
        },
        {
            itemId: 81,
            itemCategory: { categoryAbbrv: "SpP", itemCategoryId: 3 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "i",
            itemAge: [18, 24],
            itemText: "spricht in Zwei-Wort-Sätzen (z.B. Ball haben, Ball weg, da Ball), Wörter bilden eine Sineinheit",
            itemComment: "Symbolfunktion, hat kommunikative Funktion der Sprache erfasst",
            itemSuccessor: 84,
            itemValue: null
        },
        {
            itemId: 82,
            itemCategory: { categoryAbbrv: "SpP", itemCategoryId: 4 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "f",
            itemAge: [24, 30],
            itemText: "verhält sich im Gespräch egozentrisch, d.h. erzählt <em>kontextunabhängig</em>, was ihm einfällt",
            itemComment: "egozentrisches Weltbild; fehlende Impulskontrolle",
            itemSuccessor: 87,
            itemValue: null
        },
        {
            itemId: 83,
            itemCategory: { categoryAbbrv: "SpP", itemCategoryId: 5 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "f",
            itemAge: [24, 30],
            itemText: "<em>teilt die Welt</em> in <em>grobe</em> Strukturen ein; \"Schwarz-Weiß-Denken\" (groß / klein, viel / wenig, lieb / böse, schwer / leicht, immer / nie)",
            itemComment: "erstes undifferenziertes gedankliches Ordnungsprinzip (Kategorisierung),bildet sprachliche Gegensätze, kein Verständnis für Zwischentöne",
            itemSuccessor: 89,
            itemValue: null
        },
        {
            itemId: 84,
            itemCategory: { categoryAbbrv: "SpP", itemCategoryId: 6 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "i",
            itemAge: [30, 36],
            itemText: "spricht in einfachen Mehrwortsätzen bis zu sechs Wörtern (Fehler sind typisch)",
            itemComment: "",
            itemSuccessor: 86,
            itemValue: null
        },
        {
            itemId: 85,
            itemCategory: { categoryAbbrv: "SpP", itemCategoryId: 7 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "n",
            itemAge: [30, 36],
            itemText: "stellt mit deutlichem <em>Interesse an der Antwort W-Fragen</em> (wo, wann, wer, warum)",
            itemComment: "Erkenntnisinteresse",
            itemValue: null
        },
        {
            itemId: 86,
            itemCategory: { categoryAbbrv: "SpP", itemCategoryId: 8 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "i",
            itemAge: [36, 48],
            itemText: "bildet einfache <em>Satzgefüge</em> (Haupt- und Nebensatz), die <em>leichte grammatische Fehler</em> (z.B. bei der Pluralbildung / Zeitform) enthalten können",
            itemComment: "",
            itemSuccessor: 90,
            itemValue: null
        },
        {
            itemId: 87,
            itemCategory: { categoryAbbrv: "SpP", itemCategoryId: 9 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "f",
            itemAge: [36, 48],
            itemText: "bezieht sich durch Fragen und Antworten im Gespräch auf das Gegenüber, <em>braucht</em> aber die <em>Steuerung</em> durch die Bezugsperson",
            itemComment: "",
            itemSuccessor: 91,
            itemValue: null
        },
        {
            itemId: 88,
            itemCategory: { categoryAbbrv: "SpP", itemCategoryId: 10 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "n",
            itemAge: [48, 60],
            itemText: "spricht <em>bereitwillig und geordnet</em> über seine <em>Befindlichkeit</em> und begründet sie (z.B. ist ärgerlich, traurig, freut sich, weil…, hat Angst vor…, weil)",
            itemComment: "Zeichen für beginnende Selbstreflexion",
            itemValue: null
        },
        {
            itemId: 89,
            itemCategory: { categoryAbbrv: "SpP", itemCategoryId: 11 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "n",
            itemAge: [48, 60],
            itemText: "<em>differenziert</em> Qualitäten und Quantitäten (z.B. immer – oft - manchmal – nie; viel – etwas – nichts; sehr…), d.h. macht Abstufungen",
            itemComment: "das \" Schwarz - Weiß - Denken\" ist überwunden",
            itemValue: null
        },
        {
            itemId: 90,
            itemCategory: { categoryAbbrv: "SpP", itemCategoryId: 12 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "n",
            itemAge: [60, 72],
            itemText: "gebraucht die Umgangssprache korrekt (Satzbau mit Nebensätzen, Wortendungen, Aussprache, Redefluss)",
            itemComment: "",
            itemValue: null
        },
        {
            itemId: 91,
            itemCategory: { categoryAbbrv: "SpP", itemCategoryId: 13 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "n",
            itemAge: [60, 72],
            itemText: "beherrscht das <em>dialogische</em> Gespräch ohne die Steuerung durch die Bezugsperson, d.h. erzählt, hört zu, bleibt beim Thema, antworetet auf Fragen angemessen",
            itemComment: "überwindet das egozentrische Denken; Zeichen von Impulskontrolle und Konzentrationsfähigkeit",
            itemValue: null
        },
        {
            itemId: 92,
            itemCategory: { categoryAbbrv: "SpV", itemCategoryId: 1 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "n",
            itemAge: [0, 4],
            itemText: "beruhigt sich durch freundlichen Stimmklang oder Musik",
            itemComment: "",
            itemValue: null
        },
        {
            itemId: 93,
            itemCategory: { categoryAbbrv: "SpV", itemCategoryId: 2 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "i",
            itemAge: [4, 8],
            itemText: "erfasst den <em>emotionalen</em> Sinn des Gesagten über die Mimik oder den Sprachklang",
            itemComment: "",
            itemSuccessor: 94,
            itemValue: null
        },
        {
            itemId: 94,
            itemCategory: { categoryAbbrv: "SpV", itemCategoryId: 3 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "i",
            itemAge: [12, 18],
            itemText: "versteht Äußerungen, die sich auf die <em>gegenwärtige</em> Situation beziehen",
            itemComment: "",
            itemSuccessor: 96,
            itemValue: null
        },
        {
            itemId: 95,
            itemCategory: { categoryAbbrv: "SpV", itemCategoryId: 4 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "n",
            itemAge: [18, 24],
            itemText: "erkennt Dinge auf Abbildungen, d.h. sucht oder zeigt auf Befragen bekannte Gegenstände (z.B. in Bilderbüchern, auf Fotos, beim Fernsehen)",
            itemComment: "Teil der Symbolfunktion; wichtig für die Begriffsbildung",
            itemValue: null
        },
        {
            itemId: 96,
            itemCategory: { categoryAbbrv: "SpV", itemCategoryId: 5 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "i",
            itemAge: [30, 36],
            itemText: "versteht viele sprachliche Inhalte, die sich auf das <em>vertraute Alltagsleben</em> beziehen, d.h. Aussagen über nicht Gegenwärtiges, das aber zu seinem gewohnten Alltagsablauf gehört",
            itemComment: "",
            itemSuccessor: 97,
            itemValue: null
        },
        {
            itemId: 97,
            itemCategory: { categoryAbbrv: "SpV", itemCategoryId: 6 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "n",
            itemAge: [36, 48],
            itemText: "versteht Erklärungen, die aus der <em>eigenen Erfahrung</em> nachvollziehbar sind",
            itemComment: "",
            itemValue: null
        },
        {
            itemId: 98,
            itemCategory: { categoryAbbrv: "SpV", itemCategoryId: 7 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "n",
            itemAge: [48, 60],
            itemText: "hört gern vorgelesene oder erzählte Geschichten, Hörbücher / Hörspiele <em>ohne</em> Anschauungsmaterial <em>und</em> versteht den Sinn, d.h. kann Fragen beantworten",
            itemComment: "",
            itemValue: null
        },
        {
            itemId: 99,
            itemCategory: { categoryAbbrv: "SpV", itemCategoryId: 8 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "n",
            itemAge: [60, 72],
            itemText: "befolgt <em>drei</em> einfache, <em>voneinander unabhängige, gleichzeitig gegebene</em> Aufforderungen (z.B. bring den Teller in die Küche, hole das Eis aus dem Kühlschrank und bring einen großen Löffel aus der Schublade mit)",
            itemComment: "gute Merkfähigkeit",
            itemValue: null
        },
        {
            itemId: 100,
            itemCategory: { categoryAbbrv: "SpV", itemCategoryId: 9 },
            itemDevelopmentTask: { clusterId: 0, tileId: 0 },
            itemType: "n",
            itemAge: [72, 96],
            itemText: "versteht <em>als Zuhörer</em> auf umgangssprachlichem Niveau geführte Gespräche / Berichte über alle gängigen Themen (z.B. Alltagsfragen, Urlaub, Sport, Arbeit), d.h. zeigt Interesse, macht passende Kommentare, bezieht sich evtl. zu einem späteren Zeitpunkt auf zentrale Inhalte",
            itemComment: "",
            itemValue: null
        }

    ];

}