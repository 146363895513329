export default function SupplementscaleData() {
	return [
		{
			itemId: 1,
			itemCategory: { categoryAbbrv: "SeB", itemCategoryId: 1 },
			itemType: "s",
			itemAge: [0, 1],
			itemText: "wirkt wie erstarrt oder „nicht da“ (z.B. \"leerer\" Blick) emotional reglos",
			itemComment: "Zeichen für Desintegration, Traumafolgestörung, Autismus, Bindungsstörung, sozio-emotionale Deprivation",
			itemValue: { value: null, situation: null, intensity: null }
		},
		{
			itemId: 2,
			itemCategory: { categoryAbbrv: "SeB", itemCategoryId: 2 },
			itemType: "s", itemAge: [0, 1],
			itemText: "befindet sich anscheinend unerreichbar in einer Eigenwelt (\"beamt sich weg\" durch Stereotypien, Selbstgespräche, starrt ins Leere)",
			itemComment: "Zeichen für Desintegration, Traumafolgestörung, Autismus, sozio-emotionale Deprivation, Bindungsstörung",
			itemValue: { value: null, situation: null, intensity: null }
		},
		{
			itemId: 3,
			itemCategory: { categoryAbbrv: "SeB", itemCategoryId: 3 },
			itemType: "s",
			itemAge: [1, 6],
			itemText: "beansprucht seine Bezugsperson ständig und ”aufdringlich”, aber nicht aggressiv, für sich (z.B. dauerndes Quengeln / Reden / Fragen, stilles Hinterherlaufen, Suche nach Körperkontakt)",
			itemComment: "Bedürfnis nach emotionaler Verbundenheit; Zeichen für Bindungstörung",
			itemValue: { value: null, situation: null, intensity: null }
		},
		{
			itemId: 4,
			itemCategory: { categoryAbbrv: "SeB", itemCategoryId: 4 },
			itemType: "s",
			itemAge: [1, 6],
			itemText: "entfaltet seine konstruktive Aktivität nur bei einer harmonischen Grundatmosphäre (z. B. aktive Beschäftigung allein oder mit Gleichrangigen, )",
			itemComment: "Zeichen für Depression",
			itemValue: { value: null, situation: null, intensity: null }
		},
		{
			itemId: 5,
			itemCategory: { categoryAbbrv: "SeB", itemCategoryId: 5 },
			itemType: "s",
			itemAge: [1, 6],
			itemText: "ist über längere Zeit (mehr als 1 Tag) in nörgeliger, gereizter und / oder trauriger Grundstimmung (z. B. jammert, weint, lustlos, verweigert sich)",
			itemComment: "Zeichen für Depression / Bindungsstörung",
			itemValue: { value: null, situation: null, intensity: null }
		},
		{
			itemId: 6,
			itemCategory: { categoryAbbrv: "SeB", itemCategoryId: 6 },
			itemType: "s",
			itemAge: [1, 6],
			itemText: "zeigt kein Interesse (z.B. an Gegenständen, Betätigungen), ist passiv, meldet keine Bedürfnisse an, wehrt sich nicht",
			itemComment: "Zeichen für sozio-emotionale Deprivation, Depression, Selbstwertproblematik, Bindungsstörung,  und / oder Traumafolgestörung",
			itemValue: { value: null, situation: null, intensity: null }
		},
		{
			itemId: 7,
			itemCategory: { categoryAbbrv: "SeB", itemCategoryId: 7 },
			itemType: "s",
			itemAge: [1, 6],
			itemText: "vermeidet Blickkontakt",
			itemComment: "Angst vor Beziehungen; Zeichen für Autismus, sozio-emotionale Deprivation, Selbstwertproblematik und / oder Traumafolgestörung",
			itemValue: { value: null, situation: null, intensity: null }
		},
		{
			itemId: 8,
			itemCategory: { categoryAbbrv: "SeB", itemCategoryId: 8 },
			itemType: "s",
			itemAge: [1, 6],
			itemText: "zeigt steoreotype Körperbewegungen, ist dabei emotional erreichbar (z. B. Schaukeln, Hand- und Armbewegungen)",
			itemComment: "eingefrorene primäre Kreisreaktion; Selbststimulation; Zeichen für Autismus und sozio-emotionale Deprivation",
			itemValue: { value: null, situation: null, intensity: null }
		},
		{
			itemId: 9,
			itemCategory: { categoryAbbrv: "SeB", itemCategoryId: 9 },
			itemType: "s",
			itemAge: [1, 6],
			itemText: "braucht einen Gegenstand oder einen Ort stereotyp als Mittel, sich in der eigenen Welt zu stabilisieren (z.B. Schlüssel, Zeitschrift, Sessel)",
			itemComment: "Ersatzobjekt. Selbstschutz; Zeichen für Autismus / Traumafolgestörung / sozio-emotionale Deprivation / Bindungsstörung",
			itemValue: { value: null, situation: null, intensity: null }
		},
		{
			itemId: 10,
			itemCategory: { categoryAbbrv: "SeB", itemCategoryId: 10 },
			itemType: "s",
			itemAge: [1, 6],
			itemText: "handelt völlig impuls- oder affektgesteuert (z.B. Schlagen, Schreien, Essen, Trinken, kann nicht warten)",
			itemComment: "Ichschwäche, fehlende Selbststeuerung; Zeichen für Autismus / Traumafolgestörung",
			itemValue: { value: null, situation: null, intensity: null }
		},
		{
			itemId: 11,
			itemCategory: { categoryAbbrv: "SeB", itemCategoryId: 11 },
			itemType: "s",
			itemAge: [1, 6],
			itemText: "ist in Spannungszuständen (z.B. Frustrationen, Verlassenheit, Kritik, Reizüberflutung, Freude) autoaggressiv, auch mit Kontrollverlust (z.B. Selbstschädigung oder Zerstörung von wichtigem Eigentum)",
			itemComment: "Ichschwäche, fehlende Selbststeuerung; Angst; Zeichen für Desintegration, Bindungsstörung / Traumfolgestörung / Autismus / Selbstwertproblematik",
			itemValue: { value: null, situation: null, intensity: null }
		},
		{
			itemId: 12,
			itemCategory: { categoryAbbrv: "SeB", itemCategoryId: 12 },
			itemType: "s",
			itemAge: [6, 12],
			itemText: "genießt harmonische Situationen und zerstört sie dann",
			itemComment: "Angstmotivierte  Aggression; Zeichen für Bindungsstörung / Traumafolgestörung",
			itemValue: { value: null, situation: null, intensity: null }
		},
		{
			itemId: 13,
			itemCategory: { categoryAbbrv: "SeB", itemCategoryId: 13 },
			itemType: "s",
			itemAge: [6, 12],
			itemText: "hat länger anhaltende psychosomatische Symptome (Schlafstörungen, Verdauungsprobleme, Schmerzen ohne somatische Ursache)",
			itemComment: "Zeichen für Bindungsstörung / Traumafolgestörung / Depression",
			itemValue: { value: null, situation: null, intensity: null }
		},
		{
			itemId: 14,
			itemCategory: { categoryAbbrv: "SeB", itemCategoryId: 14 },
			itemType: "s",
			itemAge: [6, 12],
			itemText: "ist von oralen Substanzen abhängig (z. B. Kaffee, Zigaretten, Alkohol)",
			itemComment: "Suchtproblem, Ichschwäche",
			itemValue: { value: null, situation: null, intensity: null }
		},
		{
			itemId: 15,
			itemCategory: { categoryAbbrv: "SeB", itemCategoryId: 15 },
			itemType: "s",
			itemAge: [6, 12],
			itemText: "konzentriert sich, wenn er allein ist, max. 1-2 Min. auf eine Tätigkeit (z.B. lässt sich durch jeden Reiz ablenken oder träumt, beginnt mit Stereotypien)",
			itemComment: "Wahrnehmungsstörung / Beziehungsproblematik, fehlende Impulskontrolle; Zeichen für Traumafolgestörung / Depression / sozio-emotionale Deprivation",
			itemValue: { value: null, situation: null, intensity: null }
		},
		{
			itemId: 16,
			itemCategory: { categoryAbbrv: "SeB", itemCategoryId: 16 },
			itemType: "s",
			itemAge: [12, 24],
			itemText: "befindet sich ständig in Unruhe / Anspannung (z.B. läuft ständig hin und her, ständig in Bewegung, chaotisch aktiv)",
			itemComment: "Wahrnehmungsstörung / Beziehungsproblematik, fehlende Impulskontrolle; Zeichen für Autismus oder Traumafolgestörung",
			itemValue: { value: null, situation: null, intensity: null }
		},
		{
			itemId: 17,
			itemCategory: { categoryAbbrv: "SeB", itemCategoryId: 17 },
			itemType: "s",
			itemAge: [12, 24],
			itemText: "nähert sich distanzlos jedem,auch fremden Personen (z.B. spricht sie an, fasst sie an)",
			itemComment: "Zeichen für Bindungsstörung",
			itemValue: { value: null, situation: null, intensity: null }
		},
		{
			itemId: 18,
			itemCategory: { categoryAbbrv: "SeB", itemCategoryId: 18 },
			itemType: "s",
			itemAge: [12, 24],
			itemText: "vermeidet es neue Dinge / Tätigkeiten auszuprobieren (z. B. zeigt keine Neugier, verweigert, zeigt Angst bei Aufforderungen)",
			itemComment: "Zeichen für Selbstwertproblematik /  Depression / Autismus / sozio-emotionale Deprivation",
			itemValue: { value: null, situation: null, intensity: null }
		},
		{
			itemId: 19,
			itemCategory: { categoryAbbrv: "SeB", itemCategoryId: 19 },
			itemType: "s",
			itemAge: [24, 36],
			itemText: "sucht ständig Aufmerksamkeit durch positives (z.B. helfen wollen) oder negatives Verhalten (z.B. stören, Unsinn machen, andere ärgern)",
			itemComment: "Angst vor Verlassenheit, schwaches Selbstwertgefühl; Zeichen für Bindungsstörung",
			itemValue: { value: null, situation: null, intensity: null }
		},
		{
			itemId: 20,
			itemCategory: { categoryAbbrv: "SeB", itemCategoryId: 20 },
			itemType: "s",
			itemAge: [24, 36],
			itemText: "gerät bei jeglicher Kritik aus dem emotionalen Gleichgewicht (z.B. weint, zieht sich zurück, wird aggressiv)",
			itemComment: "Angst vor Strafe und Liebesverlust; Zeichen für Depression / Selbstwertproblematik",
			itemValue: { value: null, situation: null, intensity: null }
		},
		{
			itemId: 21,
			itemCategory: { categoryAbbrv: "SeB", itemCategoryId: 21 },
			itemType: "s",
			itemAge: [24, 36],
			itemText: "setzt Fremd- oder Sachaggressionen (z.B. zerstört etwas, schlägt), als Durchsetzungsstrategie ein",
			itemComment: "sozial inadäquates Autonomiestreben; Zeichen für Bindungsstörung",
			itemValue: { value: null, situation: null, intensity: null }
		},
		{
			itemId: 22,
			itemCategory: { categoryAbbrv: "SeB", itemCategoryId: 22 },
			itemType: "s",
			itemAge: [24, 36],
			itemText: "unterwirft sich nach überstandenem Konflikt völlig (z.B. kurzfristige Überanpassung, übermäßige Anhänglichkeit, Versprechen, es nie mehr zu tun, fragt: magst du mich noch?)",
			itemComment: "Angst vor Strafe und Liebesverlust; Zeichen für Selbstwertproblematik / Depression",
			itemValue: { value: null, situation: null, intensity: null }
		},
		{
			itemId: 23,
			itemCategory: { categoryAbbrv: "SeB", itemCategoryId: 23 },
			itemType: "s",
			itemAge: [24, 36],
			itemText: "zeigt Angst (oft nicht nachvollziehbar) vor bestimmten Dingen oder Situationen (z. B. Maschinen, Tieren, beim Waschen, auf der Straße)",
			itemComment: "Phobie; Zeichen für Traumafolgestörung / Autismus / Bindungsstörung",
			itemValue: { value: null, situation: null, intensity: null }
		},
		{
			itemId: 24,
			itemCategory: { categoryAbbrv: "SeB", itemCategoryId: 24 },
			itemType: "s",
			itemAge: [24, 36],
			itemText: "beharrt massiv auf denselben Abläufen, Ordnungen, Lösungsstrategien (z. B. wird aggressiv bei Veränderungen)",
			itemComment: "Angst vor Strukturverlust; Zwang als Angstabwehr; Zeichen für Autismus / Traumafolgestörung / sozio-emotionale Deprivation",
			itemValue: { value: null, situation: null, intensity: null }
		},
		{
			itemId: 25,
			itemCategory: { categoryAbbrv: "SeB", itemCategoryId: 25 },
			itemType: "s",
			itemAge: [36, 48],
			itemText: "traut sich nichts zu (z.B. verweigert sich, zeigt Angst, \"kann ich nicht\") auch bei Aufgaben, die er eigentlich beherrscht",
			itemComment: "Misserfolgsorientierung; Zeichen für Depression / Selbstwertproblematik",
			itemValue: { value: null, situation: null, intensity: null }
		},
		{
			itemId: 26,
			itemCategory: { categoryAbbrv: "SeB", itemCategoryId: 26 },
			itemType: "s",
			itemAge: [36, 48],
			itemText: "will immer alle Forderungen korrekt erfüllen (z. B. riskiert keine Fehler, ist übermäßig gehorsam, ist überangepasst)",
			itemComment: "Angst vor Strafe und Liebesverlust. Zeichen für Depression / Selbstwertproblematik",
			itemValue: { value: null, situation: null, intensity: null }
		},
		{
			itemId: 27,
			itemCategory: { categoryAbbrv: "SeB", itemCategoryId: 27 },
			itemType: "s",
			itemAge: [36, 48],
			itemText: "geht in einer Gruppe unter (z.B. ist schüchtern, übermäßig zurückhaltend, beschäftigt sich allein)",
			itemComment: "Ichschwäche, mangelnde Selbstbehauptung. Zeichen für sozio-emotionale Deprivation / Bindungsstörung",
			itemValue: { value: null, situation: null, intensity: null }
		},
		{
			itemId: 28,
			itemCategory: { categoryAbbrv: "SeB", itemCategoryId: 28 },
			itemType: "s",
			itemAge: [48, 60],
			itemText: "masturbiert exzessiv",
			itemComment: "Zeichen für sozio-emotionale Deprivation",
			itemValue: { value: null, situation: null, intensity: null }
		},
		{
			itemId: 29,
			itemCategory: { categoryAbbrv: "SeB", itemCategoryId: 29 },
			itemType: "s",
			itemAge: [48, 60],
			itemText: "ist bei geringfügigen Verletzungen / Beschwerden extrem besorgt um seine körperliche Unversehrtheit / Gesundheit",
			itemComment: "fehlendes Urvertrauen; Zeichen für Depression / Traumafolgestörung / Bindungsstörung",
			itemValue: { value: null, situation: null, intensity: null }
		},
		{
			itemId: 30,
			itemCategory: { categoryAbbrv: "SeB", itemCategoryId: 30 },
			itemType: "s",
			itemAge: [48, 60],
			itemText: "findet vielfältige Wege, sich den Anfordrungen der Realität zu entziehen (z. B. findet Ausreden, träumt, baut sich Luftschlösser, zeigt Suchtverhalten",
			itemComment: "Zeichen für Selbstwertproblematik / Traumafolgestörung",
			itemValue: { value: null, situation: null, intensity: null }
		},
		{
			itemId: 31,
			itemCategory: { categoryAbbrv: "SeB", itemCategoryId: 31 },
			itemType: "s",
			itemAge: [60, 72],
			itemText: "beherrscht Andere durch gezielt eingesetztes drohendes, gewaltsames, schmeichelndes oder theatralisches Verhalten",
			itemComment: "sozial inadäquate Selbstbehauptung, ärgermotivierte Aggression; Zeichen für Bindungssstörung",
			itemValue: { value: null, situation: null, intensity: null }
		},
		{
			itemId: 32,
			itemCategory: { categoryAbbrv: "SeB", itemCategoryId: 32 },
			itemType: "s",
			itemAge: [60, 72],
			itemText: "zeigt trotz ausreichender kognitiver Kompetenzen keinerlei Norm- und Wertbewusstsein (z.B. übertritt alle Gebote, die seinen Interessen entgegenstehen)",
			itemComment: "überzogenes Größenselbst, fehlende Empathie; Zeichen für Bindungsstörung",
			itemValue: { value: null, situation: null, intensity: null }
		},
		{
			itemId: 33,
			itemCategory: { categoryAbbrv: "SeB", itemCategoryId: 33 },
			itemType: "s",
			itemAge: [60, 72],
			itemText: "pflegt keine Kontakte zu Gleichrangigen, obwohl es vom kognitiven Entwicklungsniveau möglich wäre (z.B. ist Einzelgänger, hat keine Freunde)",
			itemComment: "soziale Isolation; Zeichen für Autismus / Depression / Bindungsstörung",
			itemValue: { value: null, situation: null, intensity: null }
		}
	];
}