import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import DevelopmentTasksCanvasReact from './DevelopmentTasksCanvasReact';

export default class DevelopmentTasksCanvasCard extends React.Component {
    constructor(props) {
        super(props);
        this.props = props;
    }

    render() {
        return (
            <Card>
                <CardContent>
                    <DevelopmentTasksCanvasReact items={this.props.items} />
                </CardContent>
            </Card>
        );
    }
}