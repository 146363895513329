import React, { useRef, useEffect } from 'react'
import DevelopmentTasksCanvas from './DevelopmentTasksCanvas';

const DevelopmentTasksCanvasReact = props => {

    const canvasRef = useRef(null)

    useEffect(() => {
        const items = props.items;

        const canvas = canvasRef.current;
        // const ctx = canvas.getContext('2d');

        const developmentTasksCanvas = new DevelopmentTasksCanvas(canvas, items, false);

        canvas.width = developmentTasksCanvas.calculateCanvasWidth();
        canvas.height = developmentTasksCanvas.calculateCanvasHeight();

        console.log("Width:" + canvas.width);
        console.log("Height:" + canvas.height);


        //do some math to find out if rescaling is needed
        const ratio = developmentTasksCanvas.getDevicePixelRatio();
        const width = canvas.width / ratio;
        const height = canvas.height / ratio;

        canvas.style.width = `${width}px`;
        canvas.style.height = `${height}px`;

        // ctx.clearRect(0, 0, canvas.width, canvas.height);


        // drawAgeRanges(ctx, ageRanges);
        // var i = 0;
        // categories.forEach(element => {
        //     drawCategoryBorders(ctx, getItemsByCategory(items, element), getAgeRanges(items), i);
        //     drawCategory(ctx, getItemsByCategory(items, element), getAgeRanges(items), i);
        //     i++;
        // });

        developmentTasksCanvas.draw();



    }, [props.items])

    return <canvas ref={canvasRef} /> //{...props}
}

export default DevelopmentTasksCanvasReact