import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import MainscaleCanvasReact from './MainscaleCanvasReact';

export default class MainscaleCanvasCard extends React.Component {
    constructor(props) {
        super(props);
        this.props = props;
    }

    render() {
        return (
            <Card>
                <CardContent>
                    <MainscaleCanvasReact items={this.props.items} questionaireType={this.props.questionaireType} />
                </CardContent>
            </Card>
        );
    }
}