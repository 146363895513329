import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import MainscaleCanvasReact from './MainscaleCanvasReact';
import DevelopmentTasksCanvasReact from './DevelopmentTasksCanvasReact';
import {Tab} from "@material-ui/core";
import {TabContext, TabList, TabPanel} from "@material-ui/lab";

export default class MainscaleInterimResultDialog extends React.Component {
    constructor(props) {
        super(props);
        this.props = props;
        this.state = { value: "1" };
    }

    render() {
        // const value = this.state.value;

        const handleChange = (event, newValue) => {
            this.setState({value: newValue});
        };

        return (
            <Dialog
                open={this.props.open}
                onClose={this.props.handleClose}
                aria-labelledby="dialog-title"
                aria-describedby="dialog-description"
                maxWidth={false}
            >
                <DialogTitle id="dialog-title">{"Zwischenergebnis"}</DialogTitle>
                <DialogContent>
                    <TabContext value={this.state.value}>
                        <TabList onChange={handleChange} centered>
                            <Tab label="Grundskala" value="1" />
                            <Tab label="Entwicklungsaufgaben" value="2" />
                        </TabList>
                        <TabPanel value="1">
                            <MainscaleCanvasReact items={this.props.items} questionaireType={this.props.questionaireType} />
                        </TabPanel>
                        <TabPanel value="2">
                            <DevelopmentTasksCanvasReact items={this.props.items} />
                        </TabPanel>
                    </TabContext>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.props.handleClose} color="primary">
                        Erhebung fortsetzen
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}